import {iBookingColors, iColors, iStatusColor} from "@/utils/interfaces";
import {Color} from "@/utils/functions";

const Dark : iColors = {
	primary: new Color('#08044D'),
	secondary: new Color('#4A2E87'),
	tertiary: new Color('#FF76AD'),
	forth: new Color('#F1D1D0'),
	text: new Color('#E0E0E0'),
}

const Light : iColors = {
	primary: new Color('#E0E0E0'),
	secondary: new Color('#F1D1D0'),
	tertiary: new Color('#FF76AD'),
	forth: new Color('#4A2E87'),
	text: new Color('#08044D'),
}

const BookingPalette : iBookingColors = {
	free: new Color('#B0F0A5'),
	pending: new Color('#FFD09E'),
	booked: new Color('#AEF0FF'),
	confirmed: new Color('#FFA9DD'),
	completed: new Color('#A1B4FF'),
	cancelled: new Color('#FB9F9E'),
	refused: new Color('#E8A8B5'),
	paused: new Color('#9CAFAA' ),
}

const StatusColor: iStatusColor = {
	success: new Color('#3cb028'),
	warning: new Color('#d07617'),
	error: new Color('#dc1916'),
}

export {
	Dark,
	Light,
	BookingPalette,
	StatusColor,
}
